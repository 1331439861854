<template>
  <div class="flex flex-row mt-20 items-center justify-center h-screen">
    <carousel :slides="allslides" />
  </div>
</template>
<script>
import Carousel from "../components/Carousel/Carousel.vue";
// import Comingsoon from '../assets/Comingsoon.jpg';
export default {
  components: { Carousel },
  data() {
    return {
      allslides: [
        { img: "", title: "Introducing Feeds", subtitle: "" },
        {
          img: "",
          title: "Stay in the loop",
          subtitle: "Ask questions and catch up with classes",
        },
        {
          img: "",
          title: "Help friends out",
          subtitle: "Answer questions correctly and gain rep points",
        },
        {
          img: "",
          title: "Keep track of class material",
          subtitle:
            "Save notes or pdf’s you find here or share yours with classmates",
        },
      ],
    };
  },
};
</script>